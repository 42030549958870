

  
  .container {
    background: #fff;
    padding: 3em 5em;
  }
  
  input {
    display: none;
  }
  
  .file-drop-error {
    width: 100%;
    text-align: center;
    color: red;
    display: inline-block;
    margin-bottom: 1em;
  }
  
  .file-properties {
    margin-top: 1em;
  }
  
  .file-properties ul {
    list-style: none;
    text-align: center;
  }